import React, { useRef, Component, useState, useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import '../css/form.scss';


function Form() {
    const formContent = {
        inputFirstName: "",
        inputEmail: "",
        inputPhone: "",
        inputTypeNettoyage: "",
        inputFrequence: "",
        inputInfosComplementaire: ""
    }

    const [formValue, setFormValue] = useState(formContent);
    const [show, setShow] = useState(false);

    const componentDidMount = (event) => {

        console.log(formValue);

        event.preventDefault();
        // Simple POST request with a JSON body using fetch
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(formValue)
        };

        fetch("http://montero-nettoyage.fr:9000/login", requestOptions)
            .then(response => {
                response.json();
            })

            setShow(true);
            setTimeout(() => setShow(false), 3000); // Cache le div après 3 secondes
    
            document.querySelector('#inputFirstName').value = "";
            document.querySelector('#inputEmail').value = "";
            document.querySelector('#inputPhone').value = "";
            document.querySelector('#inputTypeNettoyage').value = "Type de nettoyage souhaitez ?";
            document.querySelector('#inputFrequence').value = "Fréquence de nettoyage souhaitez ?";
            document.querySelector('#inputInfosComplementaire').value = "";
        }

    const handleChange = (event) => {

        const inputName = event.target.name;
        const inputValue = event.target.value;

        formValue[inputName] = inputValue;
        setFormValue(formValue);
    }
   

    return (
        <div className="div_form">
            {
                show && <p id="p-alert">Votre demande de devis a bien été envoyé.</p>
            }
            <form onSubmit={componentDidMount}>
                <div className="col-md-10 inputControl">
                    <input id="inputFirstName" className="form-control" type="text" name="inputFirstName" placeholder="Nom*" onChange={handleChange} />
                </div>
                <div className="col-md-10 inputControl">
                    <input id="inputEmail" className="form-control" type="email" name="inputEmail" placeholder="Email*" onChange={handleChange} />
                </div>
                <div className="col-md-10 inputControl">
                    <input id="inputPhone" className="form-control" type="text" name="inputPhone" placeholder="Téléphone" onChange={handleChange} />
                </div>

                <select id="inputTypeNettoyage" className="form-select " aria-label="Default select example" name="inputTypeNettoyage" onChange={handleChange}>
                    <option selected>Type de nettoyage souhaitez ?</option>
                    <option value="bureaux">Bureaux</option>
                    <option value="commerce">Commerce</option>
                    <option value="finDeChantier">Fin de chantier</option>
                    <option value="emmenagement">Emménagement/déménagement</option>
                    <option value="immeuble">Immeuble/co-propriété</option>
                    <option value="vitres">Vitres</option>
                    <option value="autres">Autres</option>
                </select>

                <select id="inputFrequence" className="form-select" aria-label="Default select example" name="inputFrequence" onChange={handleChange}>
                    <option selected>Fréquence de nettoyage souhaitez ?</option>
                    <option value="journalier">Journalier</option>
                    <option value="plusieursFoisParSemaine">Plusieurs fois par semaine</option>
                    <option value="1FoisParSemine">1 fois par semaine</option>
                    <option value="1a3FoisParMois">1 à 3 fois par mois</option>
                    <option value="occasionnelle">Occasionnelle</option>
                </select>

                <div className="col-md-12 div-controlTextarea">
                    <textarea id="inputInfosComplementaire"  className="form-control-textarea" rows="3" placeholder='Informations complémentaires' name="inputInfosComplementaire" onChange={handleChange}></textarea>
                </div>

                <div className="col-12 div-submit">
                    <button type="submit" className="btn btn-signIn-style">Demande de devis gratuit</button>
                </div>
            </form>
        </div>
    )
} 
export default Form;
