import './css/app.scss';
import Header from './component/Header';
import Form from './component/Form';
import icon_bureau from './assets/icon_bureau01.png';
import icon_phone from './assets/phone.svg';
import icon_commerce from './assets/icon_commerce.png';
import icon_vitre from './assets/icon_vitre.png';
import logo from './assets/cleanMan02.png';




function App() {
  return (
    <div className="main_container">
      <header>
        <div></div>
      </header>

      <div id="block_central">
        <div className="block_content">

          <div className="block_logoEntreprise">
            <img src={logo} alt="logo de l'entreprise" />
          </div>

          <div className="block_text">
              <p id="text_monteroNettoyage" className="block_titre_text"><span>MONTERO</span> NETTOYAGE,</p> <br />
              <p id="text_description" className="block_titre_text">Entreprise de nettoyage à Lyon et ses environs</p>
          </div>
          
        </div>
      
        <div className="form_content">
          <div className="block_phone">
            <p className="phone_number">
              <img className="icon_phone" src={icon_phone} alt="icone telephone portable" />
              <span>06 64 65 04 68</span>
            </p>
          </div>
          <div className="block_textForm">
            <p className="textForm">Pour une demande de devis, <br />remplissez ce formulaire et vous serez recontacté dans la journée</p>
          </div>
          <Form></Form>
        </div>
      </div>

      {/*<footer>
        <div id="infos_footer">
          <a href='#'>Mentions légales|</a>
          <a href='#'>Infos cookies|</a>
          <a href='#'>Vies privée</a>
        </div>
  </footer>*/}
    </div>
  );
}

export default App;





